import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    staticClass: "writeoff-offsite",
    attrs: {
      "after-close": _vm.afterModalClose,
      "title": "Offsite Writeoff"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "loading": _vm.isLoading,
            "type": "primary"
          },
          on: {
            "click": _vm.submitModal
          }
        }, [_vm._v(" Record Writeoff (" + _vm._s(_vm.selectedRowKeys.length) + ") ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("text-input", {
    attrs: {
      "value": _vm.record.id,
      "label": "Intake Number",
      "form-item": "",
      "disabled": true
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("date-picker", {
    attrs: {
      "value": _vm.currentDate,
      "disabled": true,
      "label": "Date",
      "form-item": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("text-area", {
    attrs: {
      "label": "Writeoff Reason",
      "rows": 1,
      "rules": "required"
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("reason", $event);
      }
    }
  })], 1)], 1), _c("resource", {
    attrs: {
      "name": "grower.offsite-storages.bins",
      "api-url": _vm.apiUrl
    }
  }, [_c("list-composer", {
    attrs: {
      "title": "Select Bins to writeoff",
      "has-edit": false,
      "has-action": false,
      "has-active": false,
      "data-source": _vm.bins,
      "hide-pagination": true,
      "selected-row-keys": _vm.selectedRowKeys,
      "on-select-change": _vm.onSelectChange
    }
  }, [_c("text-field", {
    attrs: {
      "data-index": "sscc",
      "title": "SSCC"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var WriteoffOffsiteStorage_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "WriteoffOffsiteStorage",
  inject: ["resourceProps"],
  data() {
    const [storageProps, binProps] = this.resourceProps;
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      binProps,
      apiUrl: apiUrl2,
      storageProps,
      writeoffReason: "",
      visible: true,
      selectedRowKeys: [],
      isLoading: false,
      currentDate: this.$moment()
    };
  },
  computed: {
    customUrl() {
      return `${this.apiUrl}/grower/offsite-storages/${this.$route.params.id}/bins`;
    },
    record() {
      return this.storageProps.crud.getEntity(this.$route.params.id) || {};
    },
    listBins() {
      return this._.get(this.bins);
    },
    bins() {
      return this.binProps.crud.getList();
    }
  },
  async mounted() {
    const customUrl = `${this.apiUrl}/grower/offsite-storages/${this.$route.params.id}/bins`;
    await this.binProps.crud.fetchList(customUrl);
  },
  methods: {
    async submitModal() {
      this.isLoading = true;
      this.storeValue("ids", this.selectedRowKeys);
      const customUrl = `${this.apiUrl}/grower/offsite-storages/${this.$route.params.id}/bins`;
      try {
        await this.storageProps.crud.submitEntity("replace", customUrl);
      } finally {
        this.isLoading = false;
        this.visible = false;
      }
    },
    cancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push(this.binProps.redirectRoute);
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.storageProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var WriteoffOffsiteStorage = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "writeoff-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.offsite-storages", "grower.offsite-storages.bins"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/offsite-storage", "/watties-grower/offsite-storage"]
    }
  }, [_c("writeoff-offsite-storage")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    WriteoffOffsiteStorage
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
